import React, { useEffect, useState } from 'react'
import { silentAuth } from '../../utils/auth0'
import { Provider } from 'react-redux'
import { store } from '../../store'
import { IntlProvider } from 'gatsby-plugin-intl'
import { TAG_ID, udtechServerMode } from '../../utils/consts'
import { maxmindGeoIp } from '../../utils/maxmindGeoIp'
import moment from 'moment'
import { pushToDataLayer } from '../../utils/pushToDataLayer'
import { Loader } from '../loader'

interface Props {
  children: React.ReactNode
}

const Layout: React.FC<Props> = ({ children }) => {

  return (
    <Provider store={store || {}}>
      <IntlProvider locale='es'>
        <noscript key={1}>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${TAG_ID}`}
            height='0'
            width='0'
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
      {children}
      </IntlProvider>
    </Provider>
  )


}

export default Layout
